export const customStylesHomePage = {
  container: (provided, state) => ({
    ...provided,
    cursor: "pointer",
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "transparent",
    borderRadius: 0,
    borderColor: "#4960EA",
    cursor: "pointer",
    "&:hover": {
      borderColor: "#4960EA",
    },
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#FBFBFC",
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    backgroundColor: "#FBFBFC",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#FBFBFC",
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: "#4960EA",
  }),
  input: (provided, state) => ({
    ...provided,
    color: "#FBFBFC",
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: "#FBFBFC",
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    color: "#FBFBFC",
  }),

  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2A34",
    marginTop: 2,
    marginBottom: 0,
    borderRadius: 0,
    borderLeft: "1px solid #4960EA",
    borderRight: "1px solid #4960EA",
    borderBottom: "1px solid #4960EA",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#FBFBFC",
  }),
  option: (provided, state) => ({
    ...provided,
    color: "#FBFBFC",
    cursor: "pointer",
    backgroundColor: "#2B2A34",
    borderBottom: "1px solid #23222B",
    "&:hover": {
      backgroundColor: "#23222B",
    },
    "&:last-child": {
      borderBottom: "none",
    },
  }),
}

export const customStylesGamePage = {
  container: (provided, state) => ({
    ...provided,
    cursor: "pointer",
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "transparent",
    borderRadius: 0,
    borderColor: "#4960EA",
    cursor: "pointer",
    "&:hover": {
      borderColor: "#4960EA",
    },
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    display: "none",
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: "none",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#FBFBFC",
  }),
  input: (provided, state) => ({
    ...provided,
    color: "#FBFBFC",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#FBFBFC",
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2A34",
    marginTop: 2,
    marginBottom: 0,
    borderRadius: 0,
    borderLeft: "1px solid #4960EA",
    borderRight: "1px solid #4960EA",
    borderBottom: "1px solid #4960EA",
  }),
  option: (provided, state) => ({
    ...provided,
    color: "#FBFBFC",
    cursor: "pointer",
    backgroundColor: "#2B2A34",
    borderBottom: "1px solid #23222B",
    "&:hover": {
      backgroundColor: "#23222B",
    },
    "&:last-child": {
      borderBottom: "none",
    },
  }),
}
